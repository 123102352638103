<template>
  <!--begin::Amenities-->
  <div v-if="isCan" class="card card-custom">
    <!--begin::Header-->
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label font-weight-bolder text-dark">{{ $t("MANAGEMENT.AMENITIES") }}
          <span class="d-block text-muted font-weight-lighter pt-2 font-size-sm">{{ $t("MANAGEMENT.AMENITIES_DESC") }}</span>
        </h3>
      </div>
      <div class="card-toolbar">
        <!--begin::Button-->
        <b-button v-if="isCanEdit"
                  v-b-modal.modal-add-edit-amenity
                  class="btn bg-light-primary font-weight-bolder font-size-sm">
          <i class="fas fa-plus icon-md"></i>{{ $t("MANAGEMENT.ADD_AMENITIES") }}
        </b-button>
        <!--end::Button-->
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div v-if="isLoading" class="card-body">
      <!--begin::Dummy-->
      <template v-if="amenitiesList.items.length === 0 && !notFound">
        <div class="justify-content-center row">
          <div class="col-auto d-flex">
            <AmenitiesImage/>
          </div>
        </div>
        <div class="justify-content-center row">
          <h5 class="col-auto font-weight-bolder text-dark my-5 text-center">{{ $t("MANAGEMENT.AMENITIES_NOT_YET_TITLE") }}
            <span v-if="isCanEdit" class="d-block font-weight-lighter pt-2 font-size-sm">{{ $t("MANAGEMENT.AMENITIES_NOT_YET_DESC") }}</span>
            <b-button v-if="isCanEdit"
                      v-b-modal.modal-add-edit-amenity
                      @click="onAddAmenity"
                      class="btn bg-primary text-light font-weight-bolder my-4">{{ $t("MANAGEMENT.ADD_AMENITIES") }}
            </b-button>
          </h5>
        </div>
      </template>
      <!--end::Dummy-->
      <template v-if="amenitiesList.items.length > 0 || notFound">
        <!--begin::Search-->
        <div class="row align-items-center mb-7">
          <div class="col-lg-10 col-xl-10">
            <div class="row align-items-center">
              <div class="col-md-4 my-2 my-md-0">
                <div class="input-icon">
                  <input type="text" class="form-control" v-model="filter_name" :placeholder="$t('SELECT.SEARCH')" />
                  <span><i class="flaticon-search text-muted"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::Search-->
        <!--begin::Table-->
        <div class="table-responsive">
          <b-table
              id="amenities-table"
              class="table-vertical-center"
              show-empty
              :items="amenitiesList.items"
              :fields="fields"
              :per-page="0"
              :current-page="amenitiesList.pagination.page">
            <template #empty="scope">
              <h6>{{ $t("TABLE.EMPTY_TITLE") }}</h6>
            </template>
            <template #head()="data">
              <span class="pl-0 text-muted font-weight-lighter">{{ data.label }}</span>
            </template>
            <template #head(actions)="data">
              <span class="pl-0 text-muted font-weight-lighter float-right">{{ data.label }}</span>
            </template>
            <template #cell(name)="data">
              <h6 class="font-weight-bolder text-dark mb-0">{{ data.item.name }}</h6>
            </template>
            <template #cell(desks)="data">
              <template v-if="data.item.forDesks"><Check/></template>
            </template>
            <template #cell(meeting-rooms)="data">
              <template v-if="data.item.forMeetingRooms"><Check/></template>
            </template>
            <template #cell(actions)="data">
          <span v-if="isCanEdit" class="float-right">
            <b-button class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
                      @click="onEditOffice(data.item)"
                      v-b-modal.modal-add-edit-amenity
                      v-b-tooltip.hover.v-dark="$t('MANAGEMENT.EDIT_TOOLTIP_TEXT')">
              <Edit/>
            </b-button>
            <b-button class="btn btn-icon btn-light btn-hover-primary btn-sm"
                      @click="onDeleteOffice(data.item)"
                      v-b-modal.modal-delete-amenity
                      v-b-tooltip.hover.v-dark="$t('MANAGEMENT.DELETE_TOOLTIP_TEXT')">
              <Trash/>
            </b-button>
          </span>
            </template>
          </b-table>
        </div>
        <!--end::Table-->
        <!--begin: Pagination-->
        <Pagination
            name="amenities-table"
            :pagination="amenitiesList.pagination"
            @changePageLimit="changePageLimit">
        </Pagination>
        <!--end: Pagination-->
      </template>
    </div>
    <!--end::Body-->
    <!--begin::Add Edit Modal-->
    <AddEditAmenityModal
        :form="form"
        :isEdit="isEdit"
        @changeIsEdit="toggleIsEdit"
        @changeForm="clearForm"/>
    <!--end::Add Edit Modal-->
    <!--begin::Delete Modal-->
    <b-modal
        id="modal-delete-amenity"
        centered>
      <template #modal-header="{ close }">
        <div class="w-100">
          <h5 class="float-left font-weight-bolder text-dark">{{ $t("MANAGEMENT.DELETE_AMENITIES_TITLE") }}</h5>
          <span @click="close()" class="float-right cursor-pointer">
            <span class="font-size-h5" aria-hidden="true">&times;</span>
          </span>
        </div>
      </template>
      <h5 class="float-left font-weight-bolder text-dark">{{ $t("MANAGEMENT.DELETE_AMENITIES_DESC") }}</h5>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="light-primary"
              class="float-left font-weight-bolder"
              @click="resetModal">
            {{ $t("MANAGEMENT.CANCEL_BUTTON") }}
          </b-button>
          <p class="float-right">
            <b-button
                class="font-weight-bolder"
                variant="danger"
                @click="handleDelete">
              {{ $t("MANAGEMENT.DELETE_BUTTON") }}
            </b-button>
          </p>
        </div>
      </template>
    </b-modal>
    <!--begin::Delete Modal-->
  </div>
  <!--end::Amenities-->
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { DELETE_AMENITY, GET_AMENITIES_LIST } from "@/core/services/store/management/amenities.module";

import AmenitiesImage from "@/view/layout/svg/AmenitiesImage";
import Check from "@/view/layout/svg/Check";
import Edit from "@/view/layout/svg/Edit";
import Trash from "@/view/layout/svg/Trash";
import AddEditAmenityModal from "@/view/layout/modal/AddEditAmenityModal";
import Pagination from "@/view/layout/pagination/Pagination";

export default {
  name: "Amenities",
  components: {
    AmenitiesImage,
    Check,
    Edit,
    Trash,
    AddEditAmenityModal,
    Pagination
  },
  mounted() {
    this.$store.dispatch(GET_AMENITIES_LIST).then(() => {
      this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MANAGEMENT.AMENITIES") }]);
      this.isLoading = true;
      localStorage.setItem("pageNumber", JSON.stringify({ page: 1, limit: 20 }));
    });
  },
  data() {
    return {
      isEdit: false,
      isLoading: false,
      notFound: false,
      form: {
        name: '',
        forDesks: false,
        forMeetingRooms: false
      },
      filter_name: '',
      fields: [
        { key: 'name', label: this.$t("MANAGEMENT.NAME_TABLE_TITLE") },
        { key: 'desks', label: this.$t("MANAGEMENT.DESKS_TABLE_TITLE"), thClass: 'text-center', tdClass: 'text-center' },
        { key: 'meeting-rooms', label: this.$t("MANAGEMENT.MEET_ROOMS_TABLE_TITLE"), thClass: 'text-center', tdClass: 'text-center' },
        { key: 'actions', label: this.$t("MANAGEMENT.ACTIONS_TABLE_TITLE") }
      ],
      deleteFormId: null
    }
  },
  watch: {
    filter_name(val) {
      this.notFound = val.length > 0;
      this.$store.dispatch(GET_AMENITIES_LIST, { name: val });
    }
  },
  computed: {
    ...mapGetters([
      "amenitiesList"
    ]),
    isCan() {
      if (this.$store.getters.isCan) {
        let role = this.$store.getters.isCan.find(item => item.includes(this.$route.meta.permissions[1]))
        if (role === undefined) {
          this.$router.push({ name: 'my-office' })
          return false;
        } else {
          return true;
        }
      }
    },
    isCanEdit() {
      if (this.$store.getters.isCan) {
        let role = this.$store.getters.isCan.find(item => item.includes(this.$route.meta.permissions[2]));
        return role !== undefined;
      }
    }
  },
  methods: {
    changePageLimit(val) {
      localStorage.setItem("pageNumber", JSON.stringify(val));
      this.$store.dispatch(GET_AMENITIES_LIST, val);
    },
    toggleIsEdit(variable) {
      this.isEdit = variable;
    },
    clearForm(variable) {
      this.form = variable;
    },
    onAddAmenity() {
      this.isEdit = false;
    },
    onEditOffice(item) {
      this.isEdit = true;
      this.form = item;
    },
    onDeleteOffice(item) {
      this.deleteFormId = item.id;
    },
    resetModal() {
      this.deleteFormId = null;
      this.$bvModal.hide('modal-delete-amenity');
    },
    handleDelete() {
      this.$store.dispatch(DELETE_AMENITY, this.deleteFormId)
          .then(() => {
            this.deleteFormId = null;
            let pageData = JSON.parse(localStorage.getItem("pageNumber"));
            this.changePageLimit(pageData);
          });
      this.$nextTick(() => {
        this.$bvModal.hide('modal-delete-amenity');
      })
    }
  }
}
</script>

<style scoped>

</style>
