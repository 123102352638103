<template>
  <!--begin::Add Edit Amenity Modal-->
  <b-modal
      id="modal-add-edit-amenity"
      ref="modal"
      centered
      @hidden="resetModal"
  >
    <template #modal-header="{ close }">
      <div class="w-100">
        <h5 class="float-left font-weight-bolder text-dark">{{ isEdit ? $t("MANAGEMENT.EDIT_AMENITIES_TITLE") : $t("MANAGEMENT.ADD_AMENITIES_TITLE") }}</h5>
        <span @click="close()" class="float-right cursor-pointer">
            <span class="font-size-h5" aria-hidden="true">&times;</span>
          </span>
      </div>
    </template>
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
          label-for="name-input"
          :invalid-feedback="$t('MANAGEMENT.NAME_AMENITY_INVALID_FEEDBACK')"
          :state="nameState"
      >
        <template slot="label">{{ $t("MANAGEMENT.NAME_AMENITY_INPUT_TITLE") }} <span class="font-weight-bolder text-danger">*</span></template>
        <b-form-input
            id="name-input"
            :placeholder="$t('MANAGEMENT.NAME_AMENITY_INPUT_PLACEHOLDER')"
            v-model="form.name"
            :state="nameState"
            required
        ></b-form-input>
      </b-form-group>
      <b-form-group>
        <span class="d-inline-block mt-2">{{ $t("MANAGEMENT.DESK_AVAILABLE_CHECKBOX_TITLE") }}</span>
        <span class="switch float-right">
          <label>
            <input type="checkbox" :checked="form.forDesks" v-model="form.forDesks" name="select"/>
            <span></span>
          </label>
        </span>
      </b-form-group>
      <b-form-group>
        <span class="d-inline-block mt-2">{{ $t("MANAGEMENT.MEET_ROOM_AVAILABLE_CHECKBOX_TITLE") }}</span>
        <span class="switch float-right">
          <label>
            <input type="checkbox" :checked="form.forMeetingRooms" v-model="form.forMeetingRooms" name="select"/>
            <span></span>
          </label>
        </span>
      </b-form-group>
    </form>
    <template #modal-footer>
      <div class="w-100">
        <b-button
            variant="light-primary"
            class="float-left font-weight-bolder"
            @click="resetModal">
          {{ $t("MANAGEMENT.CANCEL_BUTTON") }}
        </b-button>
        <p class="float-right">
          <b-button
              class="font-weight-bolder"
              variant="primary"
              @click="handleOk">
            {{ isEdit ? $t("MANAGEMENT.SAVE_BUTTON") : $t("MANAGEMENT.ADD_BUTTON") }}
          </b-button>
        </p>
      </div>
    </template>
  </b-modal>
  <!--end::Add Edit Amenity Modal-->
</template>

<script>
import { mapGetters } from "vuex";
import { GET_AMENITIES_LIST, POST_NEW_AMENITY, PUT_AMENITY } from "@/core/services/store/management/amenities.module";

export default {
  name: "AddEditAmenityModal",
  props: {
    form: {
      type: Object
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      nameState: null
    }
  },
  computed: {
    ...mapGetters([
      "countryCityList",
      "timezonesList"
    ])
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      let emptyForm = {
        name: '',
        forDesks: false,
        forMeetingRooms: false
      };
      this.$emit('changeForm', emptyForm);
      this.$emit('changeIsEdit', false);
      this.nameState = null;
      this.$store.dispatch(GET_AMENITIES_LIST);
      this.$bvModal.hide('modal-add-edit-amenity');
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      if (this.isEdit) {
        let form = {
          name: this.form.name,
          forDesks: this.form.forDesks,
          forMeetingRooms: this.form.forMeetingRooms
        }
        this.$store.dispatch(PUT_AMENITY, { form, id: this.form.id })
            .then(() => this.$store.dispatch(GET_AMENITIES_LIST));
      } else this.$store.dispatch(POST_NEW_AMENITY, this.form)
          .then(() => this.$store.dispatch(GET_AMENITIES_LIST));
      this.$nextTick(() => {
        this.$bvModal.hide('modal-add-edit-amenity');
      })
    },
  }
}
</script>

<style scoped>

</style>
