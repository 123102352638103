<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="344.237" height="200.953" viewBox="0 0 344.237 200.953">
    <defs>
      <clipPath id="clip-path">
        <path id="Path_7947" data-name="Path 7947" d="M483.011-1730.092c0,.225-.01.447-.018.671a27.943,27.943,0,0,1-1.463,8.113c-.041.117-.081.234-.124.35-1.693,4.727-4.856,9.339-10.1,13.915q-1.59,1.389-3.44,2.771c-.288.216-.583.431-.881.647l-8.884,0-182.026-.056-13.1,0-103.039-.033s-.072-.188-.207-.55a197.821,197.821,0,0,1-6.22-20.737c-2.742-11.2-5.193-29.019-5.271-47.652v-1.939c.111-22.076,3.633-45.01,14-59.245q.182-.252.369-.5c.18-.239.36-.477.54-.713.081-.107.162-.21.245-.315,28.644-37.046,74.4-36.988,96.548-34.634,2.171.231,4.113.483,5.789.725,1.01.146,1.924.287,2.733.417,31.645,5.1,45.928-10.806,45.928-10.806,22.308-20.55,43.407-25.568,72.6-22.892,3.663.333,7.3.833,10.9,1.489a113.849,113.849,0,0,1,39.329,14.811q1.888,1.166,3.7,2.407.652.446,1.3.9,2.308,1.635,4.489,3.391a76.415,76.415,0,0,1,19.869,23.621,65.39,65.39,0,0,1,7.206,23.076q.3,2.521.408,5.115h0q.143,3.4-.049,6.919a79.441,79.441,0,0,1-2.215,14.581v0c-.1.392-.188.783-.275,1.172a65.056,65.056,0,0,0-1.436,18.88c1.478,21.939,12.889,39.706,12.792,55.781C483.015-1730.305,483.015-1730.2,483.011-1730.092Z" transform="translate(-148.248 1903.225)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2">
        <path id="Path_7946" data-name="Path 7946" d="M0-1770.556H367.11V-2000H0Z" transform="translate(0 2000)" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-3">
        <path id="Path_7950" data-name="Path 7950" d="M1421.243-1306.773h-7.3v-16.152l-.339-.193h-2.176v-.008h-12.662v.008h-.011v2.421h.011v1.665h-.011v2.42h.011v1.671h-.011v2.421h.011v.009l-1.342-.009v-12.665h-14.208v1.6h-.007v2.42h.007v2.128h-.007v2.42h.007v1.671h-.007v2.421h.007v1.546h-.007v2.421h.007v1.546h-.007v2.421h.007v1.456h-.007v1.537h.007v.168H1367.2v13.856h-5.983v3.49h-6.452v-6.011h-11.605v-16.33h-1.815v-1.627h-.259v1.627h-1.505v0h-7.527v-1.624h-.26v1.624h-1.961v8.212l-9.748-.045v5.069h-8.266v-31.334h-24.486v33.568h-7.478V-1309.1H1255.37v117.433l167.322-.632-1.449-114.471" transform="translate(-1255.37 1325.184)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-5">
        <path id="Path_7953" data-name="Path 7953" d="M1148.37-903.372h12.3V-998.62h-12.3Z" transform="translate(-1148.37 998.62)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-7">
        <path id="Path_7956" data-name="Path 7956" d="M2256.17-1167.824h.451v-6.581h-.451Z" transform="translate(-2256.17 1174.405)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-9">
        <path id="Path_7959" data-name="Path 7959" d="M2322.43-1210.923h.7v-12.745h-.7Z" transform="translate(-2322.43 1223.668)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-11">
        <path id="Path_7962" data-name="Path 7962" d="M1548.16-1337.5h.293v-2.347h-.293Z" transform="translate(-1548.16 1339.843)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-13">
        <path id="Path_7965" data-name="Path 7965" d="M1602.19-1337.5h.292v-2.347h-.292Z" transform="translate(-1602.19 1339.843)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-15">
        <path id="Path_7968" data-name="Path 7968" d="M1726.06-1337.5h.291v-2.347h-.291Z" transform="translate(-1726.06 1339.843)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-17">
        <path id="Path_7971" data-name="Path 7971" d="M2382.07-1339.161h.225v-1.811h-.225Z" transform="translate(-2382.07 1340.972)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-19">
        <path id="Path_7974" data-name="Path 7974" d="M2475.05-1339.161h.226v-1.811h-.226Z" transform="translate(-2475.05 1340.972)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-21">
        <path id="Path_7977" data-name="Path 7977" d="M1677.63-1337.5h.293v-2.347h-.293Z" transform="translate(-1677.63 1339.843)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-23">
        <path id="Path_7980" data-name="Path 7980" d="M1286.41-1133.451h2.884v-4.887h-2.884Z" transform="translate(-1286.41 1138.338)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-25">
        <path id="Path_7983" data-name="Path 7983" d="M1970.61-1123.075h2.884v-4.887h-2.884Z" transform="translate(-1970.61 1127.962)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-27">
        <path id="Path_7986" data-name="Path 7986" d="M1568.79-1280.3h2.884v-4.887h-2.884Z" transform="translate(-1568.79 1285.185)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-29">
        <path id="Path_7989" data-name="Path 7989" d="M1618.69-1280.3h2.884v-4.887h-2.884Z" transform="translate(-1618.69 1285.185)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-31">
        <path id="Path_7992" data-name="Path 7992" d="M2576.02-950.293h2.884v-4.887h-2.884Z" transform="translate(-2576.02 955.18)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-33">
        <path id="Path_7995" data-name="Path 7995" d="M1298.98-927.313h2.884V-932.2h-2.884Z" transform="translate(-1298.98 932.2)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-35">
        <path id="Path_7998" data-name="Path 7998" d="M2861.309-1150.37a65.025,65.025,0,0,1,1.435-18.88c.088-.389.179-.781.275-1.172v0a79.543,79.543,0,0,0,2.215-14.581H2849.19v117.2l8.884,0c.3-.216.593-.43.881-.647q1.85-1.382,3.44-2.771c5.239-4.575,8.4-9.188,10.1-13.915.041-.116.083-.233.123-.35a27.911,27.911,0,0,0,1.464-8.113c.008-.224.014-.446.017-.671q0-.16,0-.32c.1-16.075-11.314-33.842-12.789-55.781" transform="translate(-2849.19 1185.007)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-37">
        <path id="Path_8001" data-name="Path 8001" d="M2750.19-903.372h11.379V-998.62H2750.19Z" transform="translate(-2750.19 998.62)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-39">
        <path id="Path_8004" data-name="Path 8004" d="M2877.92-1133.451h2.669v-4.887h-2.669Z" transform="translate(-2877.92 1138.338)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-41">
        <path id="Path_8007" data-name="Path 8007" d="M2967.616-1057.908v0c-.1.392-.187.783-.275,1.172v-1.175h.277" transform="translate(-2967.34 1057.908)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-43">
        <path id="Path_8010" data-name="Path 8010" d="M2889.55-927.313h2.67V-932.2h-2.67Z" transform="translate(-2889.55 932.2)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-45">
        <path id="Path_8013" data-name="Path 8013" d="M261.79-1285.836h-6.134v-16.15c-.093-.066-.188-.129-.281-.193h-1.828v-.008H242.912v.008h-.01v2.422h.01v1.664h-.01v2.421h.01v1.67h-.01v2.421h.01v.008l-1.127-.008v-12.664H229.851v1.6h-.006v2.421h.006v2.128h-.006v2.421h.006v1.67h-.006v2.421h.006v1.545h-.006v2.424h.006v1.544h-.006v2.421h.006v1.457h-.006v1.537h.006v.168H216.4v13.856h-5.025v3.49h-5.417v-6.011H196.21v-16.331h-1.525v-1.627h-.218v1.627h-7.587v-1.625h-.218v1.625h-1.646v8.211l-8.187-.044v5.067h-6.942v-31.334h-7.635c-10.371,14.234-13.894,37.169-14,59.245q0,.97,0,1.939c.077,18.633,2.528,36.451,5.271,47.652a197.87,197.87,0,0,0,6.22,20.737c.135.361.207.55.207.55l103.039.038v0l-1.194-112.411" transform="translate(-148.249 1304.244)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-47">
        <path id="Path_8016" data-name="Path 8016" d="M947.974-1318.229h.189v-1.811h-.189Z" transform="translate(-947.974 1320.04)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-49">
        <path id="Path_8019" data-name="Path 8019" d="M463.272-796.943h2.422v-4.887h-2.422Z" transform="translate(-463.272 801.83)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-51">
        <path id="Path_8022" data-name="Path 8022" d="M206.467-1003.235h2.422v-4.888h-2.422Z" transform="translate(-206.467 1008.123)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-53">
        <path id="Path_8025" data-name="Path 8025" d="M967.995-839.543h2.422v-4.887h-2.422Z" transform="translate(-967.995 844.43)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-55">
        <path id="Path_8028" data-name="Path 8028" d="M1127.758-1612.954v165.43h-5.788v-166.155c2.168.231,4.11.483,5.788.725" transform="translate(-1121.97 1613.679)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-57">
        <path id="Path_8031" data-name="Path 8031" d="M2705.268-1730.1v175.71h-5.788v-180q.654.446,1.3.9,2.307,1.635,4.489,3.391" transform="translate(-2699.48 1734.394)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-59">
        <path id="Path_8034" data-name="Path 8034" d="M1445.849-542.562h-3.592l-1.007-45.7h5.486l-.887,45.7" transform="translate(-1441.25 588.26)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-61">
        <path id="Path_8037" data-name="Path 8037" d="M1347.866-226.65H1312.94v-3.155l17.8-1.6,17.125,1.6v3.155" transform="translate(-1312.94 231.41)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-63">
        <path id="Path_8040" data-name="Path 8040" d="M1283.36-216.406a2.874,2.874,0,0,1,2.874-2.874,2.873,2.873,0,0,1,2.873,2.874,2.872,2.872,0,0,1-2.873,2.873,2.873,2.873,0,0,1-2.874-2.873" transform="translate(-1283.36 219.28)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-65">
        <path id="Path_8043" data-name="Path 8043" d="M1587.81-216.406a2.873,2.873,0,0,1,2.873-2.874,2.874,2.874,0,0,1,2.873,2.874,2.873,2.873,0,0,1-2.873,2.873,2.872,2.872,0,0,1-2.873-2.873" transform="translate(-1587.81 219.28)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-67">
        <path id="Path_8046" data-name="Path 8046" d="M1333.14-585.261h-32.423a3,3,0,0,1-3-3h38.419a3,3,0,0,1-3,3" transform="translate(-1297.72 588.26)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-69">
        <path id="Path_8049" data-name="Path 8049" d="M1215.146-1044.261a4.986,4.986,0,0,1-4.986-4.986v-39.829c0-7.885,5.156-14.277,11.516-14.277h26.04c6.36,0,11.515,6.393,11.515,14.277v44.815h-44.084" transform="translate(-1210.16 1103.353)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-71">
        <path id="Path_8052" data-name="Path 8052" d="M1237.014-1044.261a2.734,2.734,0,0,1-2.734-2.734v-42.081c0-7.885,5.156-14.277,11.515-14.277h26.04c6.36,0,11.516,6.393,11.516,14.277v44.815h-46.336" transform="translate(-1234.28 1103.353)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-73">
        <path id="Path_8055" data-name="Path 8055" d="M1275.167-637.021h-62.8a3.159,3.159,0,0,1-3.159-3.159,3.159,3.159,0,0,1,3.159-3.159h62.8a3.159,3.159,0,0,1,3.159,3.159,3.159,3.159,0,0,1-3.159,3.159" transform="translate(-1209.21 643.34)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-75">
        <path id="Path_8058" data-name="Path 8058" d="M1786.18-1753.13a9.759,9.759,0,0,1,9.2-4.054s9.419-7.6,19.464-1.014c0,0,10.677-1.632,18.585,1.414,5.273,2.031,7.014,4.161,7.014,4.161l-54.265-.507" transform="translate(-1786.18 1760.885)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-77">
        <path id="Path_8061" data-name="Path 8061" d="M2137.98-1620.684a5.6,5.6,0,0,1,5.345-2.472s5.471-4.635,11.3-.618c0,0,6.2-1,10.794.862a10.208,10.208,0,0,1,4.074,2.537l-31.518-.309" transform="translate(-2137.98 1625.412)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-79">
        <path id="Path_8064" data-name="Path 8064" d="M1261.35-1457.791a7.566,7.566,0,0,1,7.218-3.339s7.39-6.261,15.271-.835c0,0,8.377-1.344,14.58,1.164,4.137,1.673,5.5,3.427,5.5,3.427l-42.572-.417" transform="translate(-1261.35 1464.178)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-81">
        <path id="Path_8067" data-name="Path 8067" d="M2500.17-1380.156a2.469,2.469,0,0,0,1.931.917,2.3,2.3,0,0,0,1.8-.917,12.245,12.245,0,0,0-3.735,0" transform="translate(-2500.17 1380.307)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-83">
        <path id="Path_8070" data-name="Path 8070" d="M1788.5-1042.253l-41.747.071,6.147-27.545,41.749-.071-6.148,27.545" transform="translate(-1746.75 1069.798)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-85">
        <path id="Path_8073" data-name="Path 8073" d="M1800.007-1042.238l-41.747.07,6.146-27.579,41.747-.071-6.146,27.58" transform="translate(-1758.26 1069.818)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-87">
        <path id="Path_8076" data-name="Path 8076" d="M1893.962-910.77l-8.672.079,6-18.3,8.673-.079-6,18.3" transform="translate(-1885.29 929.07)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-89">
        <path id="Path_8079" data-name="Path 8079" d="M1838.61-775.588h18.939v-.872H1838.61Z" transform="translate(-1838.61 776.46)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-91">
        <path id="Path_8082" data-name="Path 8082" d="M998.033-1136.374h.781v-13.458l-8-20.4L1005.1-1181.3l-.479-.618-14.739,11.421,8.156,20.81v13.31" transform="translate(-989.877 1181.916)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-93">
        <path id="Path_8085" data-name="Path 8085" d="M1117.706-1188.357h0a.8.8,0,0,0,.189-1.112l-1.188-1.677a.8.8,0,0,0-1.112-.189.8.8,0,0,0-.189,1.111l1.189,1.677a.8.8,0,0,0,1.112.189" transform="translate(-1115.259 1191.482)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-95">
        <path id="Path_8088" data-name="Path 8088" d="M988.58-1090.128a1.035,1.035,0,0,0-1.035-1.035,1.035,1.035,0,0,0-1.035,1.035,1.035,1.035,0,0,0,1.035,1.035,1.035,1.035,0,0,0,1.035-1.035" transform="translate(-986.511 1091.163)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-97">
        <path id="Path_8091" data-name="Path 8091" d="M1031.3-786.082h7.864v-.794a1.445,1.445,0,0,0-1.444-1.444H1032.8a1.5,1.5,0,0,0-1.5,1.5v.735" transform="translate(-1031.3 788.32)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-99">
        <path id="Path_8094" data-name="Path 8094" d="M1057.21-881.435h1.652v-12.5h-1.652Z" transform="translate(-1057.21 893.94)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-101">
        <path id="Path_8097" data-name="Path 8097" d="M1104.416-1172.694c.038.042.075.083.114.122l.008.009c.036.041.072.08.11.118,1.068.926,4.607-.734,8.032-3.8s5.468-6.395,4.666-7.559c-.033-.042-.068-.082-.1-.123l-.008-.009c-.034-.042-.071-.084-.109-.126a8.524,8.524,0,0,0-12.036-.672,8.525,8.525,0,0,0-.672,12.036" transform="translate(-1102.246 1186.9)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-103">
        <path id="Path_8100" data-name="Path 8100" d="M1120.629-1151.839l.008.008c.012.016.024.029.036.043a.623.623,0,0,0,.076.074c1.067.926,4.607-.733,8.031-3.8s5.469-6.393,4.668-7.558a.907.907,0,0,0-.067-.084.216.216,0,0,0-.039-.039l-.008-.01c-.726-.688-2.7-.074-5.005,1.418a25.176,25.176,0,0,0-3.054,2.353,26.615,26.615,0,0,0-2.179,2.184c-2.078,2.358-3.125,4.535-2.466,5.406" transform="translate(-1120.433 1163.482)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-105">
        <path id="Path_8103" data-name="Path 8103" d="M1143.64-1144.131a3.481,3.481,0,0,0,2.226.8,3.513,3.513,0,0,0,3.513-3.513,3.506,3.506,0,0,0-.506-1.819,25.175,25.175,0,0,0-3.054,2.353,26.6,26.6,0,0,0-2.179,2.184" transform="translate(-1143.64 1148.668)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-107">
        <path id="Path_8106" data-name="Path 8106" d="M2022.684-453.354a7.3,7.3,0,0,1-1.055-.1c-3.041-.458-7.984-2.682-8.2-5.23-.009-.111-.018-.218-.028-.319.03-1.256,1.464-5.819,20.61-6.445,0,0-.769-.029-2.015-.029-4.621,0-15.821.407-18.785,4.245,0,0-3.177-3.707-1.527-6.781a9.706,9.706,0,0,0,4.238,1.894c-.186.657-.2,1.158.084,1.306a.282.282,0,0,0,.131.032c.294,0,.649-.443,1.016-1.1a25.329,25.329,0,0,0,3.95.3,22.985,22.985,0,0,0,4.486-.411c2.889-.592,3.234-1.32,2.485-2.293,1.635-1.082,3.08-2.157,3.08-2.157s6.273,1.067,5.938,4.893c-.321,3.678-2.331,4.912-3.329,4.912a.936.936,0,0,1-.116-.006c-.8-.086-4.9-1.615-6.22-1.615-.241,0-.389.05-.408.171-.12.778,3.224,3.322,5.566,3.441,0,0-3.377,3.724-6.19,3.725a2.68,2.68,0,0,1-1.171-.256c-2.938-1.406-3.189-3.638-5.021-4.475a6.145,6.145,0,0,0-2.281-.624c-.533,0-.74.213-.359.644.756.854,4.073,1.683,5.12,3.536.9,1.593,1.721,2.741,0,2.741m3.933-16.325c-.288-.242-.593-.5-.906-.763a6.615,6.615,0,0,0-2.543-1.234c.165,0,.333,0,.5,0,.754,0,1.524.023,2.233.086a11.487,11.487,0,0,0,2.04.774c-.3.254-.786.664-1.324,1.14" transform="translate(-2011.209 471.68)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-109">
        <path id="Path_8109" data-name="Path 8109" d="M1996.58-407.591c.1-.116.21-.225.318-.326-.135.137-.244.248-.318.326m.288-.973h0a12.333,12.333,0,0,0,3.392-4.761c2.963-3.837,14.164-4.245,18.785-4.245,1.246,0,2.014.029,2.014.029-19.146.626-20.58,5.189-20.61,6.445a1.221,1.221,0,0,0,.028.319,9.686,9.686,0,0,0-2.9,2.189c.275-.3.295-.4.2-.4a3.784,3.784,0,0,0-.906.428" transform="translate(-1996.58 417.57)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-111">
        <path id="Path_8112" data-name="Path 8112" d="M1973.436-338.371h-1a10.865,10.865,0,0,1,3.057-4,3.784,3.784,0,0,1,.906-.428c.1,0,.08.1-.2.4-.147.162-.366.38-.68.67,0,0,0,0,0,0-.108.1-.215.21-.318.326a11.117,11.117,0,0,0-1.774,3.029" transform="translate(-1972.44 342.8)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-113">
        <path id="Path_8115" data-name="Path 8115" d="M1732.951-532.72a15.369,15.369,0,0,1-3-.274c-3.15-.646-1.777-1.535-.08-2.977s5.251-1.062,6.3-1.628c.926-.5-.158-1-1.869-1-.228,0-.469.008-.717.028-2.1.162-3.15,2.262-6.544,2.585-.084.008-.166.013-.25.013-3.268,0-5.728-6.071-5.728-6.071a5.6,5.6,0,0,0,1.636.214c2.269,0,4.941-.946,5.07-1.588.162-.808-6.06-.727-7.029-.969s-2.665-2.182-1.615-6.06c.646-2.386,3.373-2.845,5.375-2.845a13.539,13.539,0,0,1,2.219.179s4.444,6.787,5.736,6.948a.3.3,0,0,0,.045,0c1.154,0-2.71-6.547-2.71-6.547.1-.009.195-.013.3-.013,2.679,0,8.586,2.841,8.586,2.841s-.4,7.759.85,7.759a.209.209,0,0,0,.038,0c1.293-.161,0-7.028,0-7.028,2.424.727,4.686,3.554,4.686,3.554,2.827,4.6-3.232,8-3.232,8-1.939-6.14-18.9-11.634-18.9-11.634,18.572,7.513,18.338,12.51,17.915,13.754-.046.1-.093.2-.142.3-.823,1.761-4.034,2.456-6.936,2.455" transform="translate(-1718.793 553.29)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-115">
        <path id="Path_8118" data-name="Path 8118" d="M1768.193-499.339c-.046-.1-.112-.252-.2-.436.07.139.135.284.2.436m.067-1.059s-.6-.755-.766-.755c-.069,0-.067.125.077.475-.606-1.24-1.476-2.855-2.057-3.194a1.289,1.289,0,0,0,.142-.3c.423-1.244.657-6.241-17.915-13.754,0,0,16.965,5.494,18.9,11.634a12.884,12.884,0,0,0,1.615,5.9" transform="translate(-1747.74 517.93)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-117">
        <path id="Path_8121" data-name="Path 8121" d="M1921.859-363.946h-.934a15.825,15.825,0,0,0-.645-5.93c-.061-.151-.126-.3-.2-.436a0,0,0,0,1,0,0c-.2-.4-.34-.691-.426-.9-.143-.35-.146-.475-.077-.475.167,0,.766.755.766.755a11.35,11.35,0,0,1,1.512,6.989" transform="translate(-1919.537 371.69)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-119">
        <path id="Path_8124" data-name="Path 8124" d="M1958.389-655.861a25.329,25.329,0,0,1-3.95-.3c-.419-.067-.831-.146-1.231-.236a9.706,9.706,0,0,1-4.238-1.894,3.851,3.851,0,0,1-.954-1.242c-.073-.158-.145-.309-.212-.454-.633-1.859-.984-9.331,26.79-20.566,0,0-25.37,8.215-28.269,17.4,0,0-9.061-5.074-4.832-11.96,0,0,3.382-4.229,7.006-5.315,0,0-1.932,10.268,0,10.51a.5.5,0,0,0,.058,0c1.863,0,1.271-11.6,1.271-11.6s8.831-4.248,12.837-4.248c.158,0,.309.006.451.02,0,0-5.779,9.79-4.052,9.79.021,0,.042,0,.065,0,1.933-.242,8.577-10.389,8.577-10.389a20.236,20.236,0,0,1,3.319-.268c2.993,0,7.07.686,8.037,4.255,1.571,5.8-.966,8.7-2.416,9.061s-10.752.241-10.51,1.449c.192.959,4.187,2.374,7.58,2.374a8.371,8.371,0,0,0,2.447-.32s-3.678,9.078-8.566,9.079a3.74,3.74,0,0,1-.374-.018,11.739,11.739,0,0,1-2-.36,11.487,11.487,0,0,1-2.04-.774c-2.234-1.1-3.635-2.569-5.748-2.732-.371-.029-.73-.041-1.073-.041-2.557,0-4.178.746-2.794,1.491,1.145.616,4.281.482,6.88,1.2a6.615,6.615,0,0,1,2.543,1.234c.313.267.618.521.906.763a10.4,10.4,0,0,1,1.457,1.4c.749.973.4,1.7-2.485,2.293a22.98,22.98,0,0,1-4.486.411" transform="translate(-1940.369 686.62)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-121">
        <path id="Path_8127" data-name="Path 8127" d="M1971.33-605.942c.091-.226.188-.444.294-.652-.125.274-.225.5-.294.652m-.1-1.583h0a19.28,19.28,0,0,0,2.416-8.819c2.9-9.181,28.269-17.4,28.269-17.4-27.774,11.235-27.423,18.708-26.79,20.566a1.945,1.945,0,0,0,.212.454c-.868.507-2.171,2.922-3.076,4.776.213-.523.218-.709.116-.709-.252,0-1.147,1.128-1.147,1.128" transform="translate(-1971.23 633.74)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-123">
        <path id="Path_8130" data-name="Path 8130" d="M1952.151-402.341h-1.284a16.878,16.878,0,0,1,2.08-11.591s.895-1.128,1.147-1.128c.1,0,.1.186-.116.709-.128.317-.333.755-.637,1.349a0,0,0,0,1,0,0c-.106.208-.2.426-.294.652-1.217,3.06-1.062,7.746-.9,10.008" transform="translate(-1950.578 415.06)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-125">
        <path id="Path_8133" data-name="Path 8133" d="M1867.614-286a15.647,15.647,0,0,1-9.724-14.009h1.907a15.65,15.65,0,0,0,9.489,13.911H1868.1a1.248,1.248,0,0,0-.486.1m12.063-.1h-6.5a15.652,15.652,0,0,1-9.489-13.911h25.482a15.651,15.651,0,0,1-9.489,13.911" transform="translate(-1857.89 300.01)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-127">
        <path id="Path_8136" data-name="Path 8136" d="M1887.885-286.1H1884a15.65,15.65,0,0,1-9.489-13.911h3.887a15.652,15.652,0,0,0,9.489,13.911" transform="translate(-1874.51 300.01)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-129">
        <path id="Path_8139" data-name="Path 8139" d="M1853.34-303.71h32.16v-.48h-32.16Z" transform="translate(-1853.34 304.19)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-131">
        <path id="Path_8142" data-name="Path 8142" d="M1950.608-177.5H1935.96a1.256,1.256,0,0,1,.767-1.156,1.248,1.248,0,0,1,.486-.1h12.14a1.253,1.253,0,0,1,1.254,1.254" transform="translate(-1935.96 178.75)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-133">
        <path id="Path_8145" data-name="Path 8145" d="M956.909-681.991h-.06c-.354,0-.638-.465-.63-1.027l.955-65.949,2.814-.523-2.4,66.477c-.021.574-.319,1.022-.68,1.022" transform="translate(-956.219 749.49)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-135">
        <path id="Path_8148" data-name="Path 8148" d="M2171.969-681.991h.06c.354,0,.638-.465.63-1.027l-.954-65.949-2.814-.523,2.4,66.477c.021.574.319,1.022.68,1.022" transform="translate(-2168.89 749.49)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-137">
        <path id="Path_8151" data-name="Path 8151" d="M1055.8-765.061H902.964a1.738,1.738,0,0,1-1.739-1.738v-.5a1.739,1.739,0,0,1,1.739-1.739H1055.8a1.74,1.74,0,0,1,1.739,1.739v.5a1.739,1.739,0,0,1-1.739,1.738" transform="translate(-901.225 769.04)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-139">
        <path id="Path_8154" data-name="Path 8154" d="M450.989-167.01h-343.4c-.23,0-.418-.357-.418-.8v-.405c0-.438.188-.8.418-.8h343.4c.231,0,.418.359.418.8v.405c0,.44-.187.8-.418.8" transform="translate(-107.17 169.01)" fill="#9abee1" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-140">
        <path id="Path_8153" data-name="Path 8153" d="M0-1770.556H367.11V-2000H0Z" transform="translate(0 2000)" fill="#9abee1"/>
      </clipPath>
    </defs>
    <g id="Group_7201" data-name="Group 7201" transform="translate(-107.17 1903.225)">
      <g id="Group_7204" data-name="Group 7204" transform="translate(111.883 -1903.225)" clip-path="url(#clip-path)">
        <g id="Group_7203" data-name="Group 7203" transform="translate(-17.007 -11.102)" clip-path="url(#clip-path-2)">
          <path id="Path_7945" data-name="Path 7945" d="M143.248-1908.225H479.163v200.747H143.248Z" transform="translate(-126.814 1918.754)" fill="#ebf3f9"/>
        </g>
      </g>
      <g id="Group_7206" data-name="Group 7206" transform="translate(238.894 -1836.911)" clip-path="url(#clip-path-3)">
        <g id="Group_7205" data-name="Group 7205" transform="translate(-144.019 -77.416)" clip-path="url(#clip-path-2)">
          <path id="Path_7948" data-name="Path 7948" d="M1250.37-1330.184h168.469v134.661H1250.37Z" transform="translate(-1106.925 1407.027)" fill="#dfebf9"/>
        </g>
      </g>
      <g id="Group_7208" data-name="Group 7208" transform="translate(226.618 -1799.447)" clip-path="url(#clip-path-5)">
        <g id="Group_7207" data-name="Group 7207" transform="translate(-131.743 -114.88)" clip-path="url(#clip-path-2)">
          <path id="Path_7951" data-name="Path 7951" d="M1143.37-1003.62h13.445v96.4H1143.37Z" transform="translate(-1012.2 1117.927)" fill="#dfebf9"/>
        </g>
      </g>
      <g id="Group_7210" data-name="Group 7210" transform="translate(353.707 -1819.614)" clip-path="url(#clip-path-7)">
        <g id="Group_7209" data-name="Group 7209" transform="translate(-258.832 -94.714)" clip-path="url(#clip-path-2)">
          <path id="Path_7954" data-name="Path 7954" d="M2251.17-1179.405h1.6v7.728h-1.6Z" transform="translate(-1992.911 1273.545)" fill="#dfebf9"/>
        </g>
      </g>
      <g id="Group_7212" data-name="Group 7212" transform="translate(361.309 -1825.265)" clip-path="url(#clip-path-9)">
        <g id="Group_7211" data-name="Group 7211" transform="translate(-266.434 -89.062)" clip-path="url(#clip-path-2)">
          <path id="Path_7957" data-name="Path 7957" d="M2317.43-1228.668h1.849v13.892h-1.849Z" transform="translate(-2051.57 1317.157)" fill="#dfebf9"/>
        </g>
      </g>
      <g id="Group_7214" data-name="Group 7214" transform="translate(272.483 -1838.593)" clip-path="url(#clip-path-11)">
        <g id="Group_7213" data-name="Group 7213" transform="translate(-177.608 -75.735)" clip-path="url(#clip-path-2)">
          <path id="Path_7960" data-name="Path 7960" d="M1543.16-1344.843h1.44v3.494h-1.44Z" transform="translate(-1366.126 1420.004)" fill="#dfebf9"/>
        </g>
      </g>
      <g id="Group_7216" data-name="Group 7216" transform="translate(278.682 -1838.593)" clip-path="url(#clip-path-13)">
        <g id="Group_7215" data-name="Group 7215" transform="translate(-183.806 -75.735)" clip-path="url(#clip-path-2)">
          <path id="Path_7963" data-name="Path 7963" d="M1597.19-1344.843h1.44v3.494h-1.44Z" transform="translate(-1413.957 1420.004)" fill="#dfebf9"/>
        </g>
      </g>
      <g id="Group_7218" data-name="Group 7218" transform="translate(292.892 -1838.593)" clip-path="url(#clip-path-15)">
        <g id="Group_7217" data-name="Group 7217" transform="translate(-198.017 -75.735)" clip-path="url(#clip-path-2)">
          <path id="Path_7966" data-name="Path 7966" d="M1721.06-1344.843h1.439v3.494h-1.439Z" transform="translate(-1523.617 1420.004)" fill="#dfebf9"/>
        </g>
      </g>
      <g id="Group_7220" data-name="Group 7220" transform="translate(368.151 -1838.723)" clip-path="url(#clip-path-17)">
        <g id="Group_7219" data-name="Group 7219" transform="translate(-273.276 -75.605)" clip-path="url(#clip-path-2)">
          <path id="Path_7969" data-name="Path 7969" d="M2377.07-1345.972h1.372v2.958h-1.372Z" transform="translate(-2104.368 1421.003)" fill="#dfebf9"/>
        </g>
      </g>
      <g id="Group_7222" data-name="Group 7222" transform="translate(378.818 -1838.723)" clip-path="url(#clip-path-19)">
        <g id="Group_7221" data-name="Group 7221" transform="translate(-283.943 -75.605)" clip-path="url(#clip-path-2)">
          <path id="Path_7972" data-name="Path 7972" d="M2470.05-1345.972h1.373v2.958h-1.373Z" transform="translate(-2186.681 1421.003)" fill="#dfebf9"/>
        </g>
      </g>
      <g id="Group_7224" data-name="Group 7224" transform="translate(287.336 -1838.593)" clip-path="url(#clip-path-21)">
        <g id="Group_7223" data-name="Group 7223" transform="translate(-192.461 -75.735)" clip-path="url(#clip-path-2)">
          <path id="Path_7975" data-name="Path 7975" d="M1672.63-1344.843h1.44v3.494h-1.44Z" transform="translate(-1480.742 1420.004)" fill="#dfebf9"/>
        </g>
      </g>
      <g id="Group_7226" data-name="Group 7226" transform="translate(242.455 -1815.476)" clip-path="url(#clip-path-23)">
        <g id="Group_7225" data-name="Group 7225" transform="translate(-147.579 -98.852)" clip-path="url(#clip-path-2)">
          <path id="Path_7978" data-name="Path 7978" d="M1281.41-1143.338h4.031v6.034h-4.031Z" transform="translate(-1134.404 1241.616)" fill="#edf6ff"/>
        </g>
      </g>
      <g id="Group_7228" data-name="Group 7228" transform="translate(320.947 -1814.286)" clip-path="url(#clip-path-25)">
        <g id="Group_7227" data-name="Group 7227" transform="translate(-226.072 -100.042)" clip-path="url(#clip-path-2)">
          <path id="Path_7981" data-name="Path 7981" d="M1965.61-1132.962h4.031v6.034h-4.031Z" transform="translate(-1740.111 1232.43)" fill="#edf6ff"/>
        </g>
      </g>
      <g id="Group_7230" data-name="Group 7230" transform="translate(274.85 -1832.323)" clip-path="url(#clip-path-27)">
        <g id="Group_7229" data-name="Group 7229" transform="translate(-179.975 -82.005)" clip-path="url(#clip-path-2)">
          <path id="Path_7984" data-name="Path 7984" d="M1563.79-1290.185h4.031v6.034h-4.031Z" transform="translate(-1384.389 1371.616)" fill="#edf6ff"/>
        </g>
      </g>
      <g id="Group_7232" data-name="Group 7232" transform="translate(280.575 -1832.323)" clip-path="url(#clip-path-29)">
        <g id="Group_7231" data-name="Group 7231" transform="translate(-185.699 -82.005)" clip-path="url(#clip-path-2)">
          <path id="Path_7987" data-name="Path 7987" d="M1613.69-1290.185h4.031v6.034h-4.031Z" transform="translate(-1428.564 1371.616)" fill="#edf6ff"/>
        </g>
      </g>
      <g id="Group_7234" data-name="Group 7234" transform="translate(390.401 -1794.464)" clip-path="url(#clip-path-31)">
        <g id="Group_7233" data-name="Group 7233" transform="translate(-295.526 -119.864)" clip-path="url(#clip-path-2)">
          <path id="Path_7990" data-name="Path 7990" d="M2571.02-960.18h4.031v6.034h-4.031Z" transform="translate(-2276.068 1079.47)" fill="#edf6ff"/>
        </g>
      </g>
      <g id="Group_7236" data-name="Group 7236" transform="translate(243.897 -1791.827)" clip-path="url(#clip-path-33)">
        <g id="Group_7235" data-name="Group 7235" transform="translate(-149.022 -122.5)" clip-path="url(#clip-path-2)">
          <path id="Path_7993" data-name="Path 7993" d="M1293.98-937.2h4.031v6.034h-4.031Z" transform="translate(-1145.532 1059.127)" fill="#edf6ff"/>
        </g>
      </g>
      <g id="Group_7238" data-name="Group 7238" transform="translate(421.74 -1820.83)" clip-path="url(#clip-path-35)">
        <g id="Group_7237" data-name="Group 7237" transform="translate(-326.865 -93.498)" clip-path="url(#clip-path-2)">
          <path id="Path_7996" data-name="Path 7996" d="M2844.19-1190.007h26.056v118.351H2844.19Z" transform="translate(-2517.899 1282.931)" fill="#dfebf9"/>
        </g>
      </g>
      <g id="Group_7240" data-name="Group 7240" transform="translate(410.382 -1799.447)" clip-path="url(#clip-path-37)">
        <g id="Group_7239" data-name="Group 7239" transform="translate(-315.507 -114.88)" clip-path="url(#clip-path-2)">
          <path id="Path_7999" data-name="Path 7999" d="M2745.19-1003.62h12.526v96.4H2745.19Z" transform="translate(-2430.256 1117.927)" fill="#dfebf9"/>
        </g>
      </g>
      <g id="Group_7242" data-name="Group 7242" transform="translate(425.036 -1815.476)" clip-path="url(#clip-path-39)">
        <g id="Group_7241" data-name="Group 7241" transform="translate(-330.161 -98.852)" clip-path="url(#clip-path-2)">
          <path id="Path_8002" data-name="Path 8002" d="M2872.92-1143.338h3.817v6.034h-3.817Z" transform="translate(-2543.333 1241.616)" fill="#edf6ff"/>
        </g>
      </g>
      <g id="Group_7244" data-name="Group 7244" transform="translate(435.294 -1806.249)" clip-path="url(#clip-path-41)">
        <g id="Group_7243" data-name="Group 7243" transform="translate(-340.419 -108.079)" clip-path="url(#clip-path-2)">
          <path id="Path_8005" data-name="Path 8005" d="M2962.34-1062.908h1.424v2.323h-1.424Z" transform="translate(-2622.495 1170.413)" fill="#edf6ff"/>
        </g>
      </g>
      <g id="Group_7246" data-name="Group 7246" transform="translate(426.37 -1791.827)" clip-path="url(#clip-path-43)">
        <g id="Group_7245" data-name="Group 7245" transform="translate(-331.495 -122.5)" clip-path="url(#clip-path-2)">
          <path id="Path_8008" data-name="Path 8008" d="M2884.55-937.2h3.817v6.034h-3.817Z" transform="translate(-2553.629 1059.127)" fill="#edf6ff"/>
        </g>
      </g>
      <g id="Group_7248" data-name="Group 7248" transform="translate(111.883 -1834.509)" clip-path="url(#clip-path-45)">
        <g id="Group_7247" data-name="Group 7247" transform="translate(-17.007 -79.818)" clip-path="url(#clip-path-2)">
          <path id="Path_8011" data-name="Path 8011" d="M143.249-1309.244H259.132v131.971H143.249Z" transform="translate(-126.815 1388.489)" fill="#dfebf9"/>
        </g>
      </g>
      <g id="Group_7250" data-name="Group 7250" transform="translate(203.629 -1836.321)" clip-path="url(#clip-path-47)">
        <g id="Group_7249" data-name="Group 7249" transform="translate(-108.753 -78.006)" clip-path="url(#clip-path-2)">
          <path id="Path_8014" data-name="Path 8014" d="M942.974-1325.04h1.337v2.959h-1.337Z" transform="translate(-834.794 1402.473)" fill="#dfebf9"/>
        </g>
      </g>
      <g id="Group_7252" data-name="Group 7252" transform="translate(148.023 -1776.871)" clip-path="url(#clip-path-49)">
        <g id="Group_7251" data-name="Group 7251" transform="translate(-53.147 -137.456)" clip-path="url(#clip-path-2)">
          <path id="Path_8017" data-name="Path 8017" d="M458.272-806.83h3.57v6.034h-3.57Z" transform="translate(-405.698 943.713)" fill="#edf6ff"/>
        </g>
      </g>
      <g id="Group_7254" data-name="Group 7254" transform="translate(118.562 -1800.537)" clip-path="url(#clip-path-51)">
        <g id="Group_7253" data-name="Group 7253" transform="translate(-23.686 -113.79)" clip-path="url(#clip-path-2)">
          <path id="Path_8020" data-name="Path 8020" d="M201.467-1013.123h3.57v6.035h-3.57Z" transform="translate(-178.354 1126.339)" fill="#edf6ff"/>
        </g>
      </g>
      <g id="Group_7256" data-name="Group 7256" transform="translate(205.926 -1781.758)" clip-path="url(#clip-path-53)">
        <g id="Group_7255" data-name="Group 7255" transform="translate(-111.05 -132.569)" clip-path="url(#clip-path-2)">
          <path id="Path_8023" data-name="Path 8023" d="M962.995-849.43h3.569v6.034h-3.569Z" transform="translate(-852.518 981.426)" fill="#edf6ff"/>
        </g>
      </g>
      <g id="Group_7258" data-name="Group 7258" transform="translate(223.59 -1870.008)" clip-path="url(#clip-path-55)">
        <g id="Group_7257" data-name="Group 7257" transform="translate(-128.715 -44.32)" clip-path="url(#clip-path-2)">
          <path id="Path_8026" data-name="Path 8026" d="M1116.97-1618.679h6.935v167.3h-6.935Z" transform="translate(-988.829 1662.425)" fill="#d1e2fa"/>
        </g>
      </g>
      <g id="Group_7260" data-name="Group 7260" transform="translate(404.565 -1883.857)" clip-path="url(#clip-path-57)">
        <g id="Group_7259" data-name="Group 7259" transform="translate(-309.69 -30.471)" clip-path="url(#clip-path-2)">
          <path id="Path_8029" data-name="Path 8029" d="M2694.48-1739.394h6.935v181.151h-6.935Z" transform="translate(-2385.364 1769.291)" fill="#d1e2fa"/>
        </g>
      </g>
      <g id="Group_7262" data-name="Group 7262" transform="translate(260.218 -1752.37)" clip-path="url(#clip-path-59)">
        <g id="Group_7261" data-name="Group 7261" transform="translate(-165.343 -161.958)" clip-path="url(#clip-path-2)">
          <path id="Path_8032" data-name="Path 8032" d="M1436.25-593.26h6.633v46.846h-6.633Z" transform="translate(-1271.481 754.644)" fill="#2b468a"/>
        </g>
      </g>
      <g id="Group_7264" data-name="Group 7264" transform="translate(245.498 -1711.431)" clip-path="url(#clip-path-61)">
        <g id="Group_7263" data-name="Group 7263" transform="translate(-150.623 -202.896)" clip-path="url(#clip-path-2)">
          <path id="Path_8035" data-name="Path 8035" d="M1307.94-236.41h36.073v5.907H1307.94Z" transform="translate(-1157.891 438.733)" fill="#2b468a"/>
        </g>
      </g>
      <g id="Group_7266" data-name="Group 7266" transform="translate(242.105 -1710.04)" clip-path="url(#clip-path-63)">
        <g id="Group_7265" data-name="Group 7265" transform="translate(-147.23 -204.288)" clip-path="url(#clip-path-2)">
          <path id="Path_8038" data-name="Path 8038" d="M1278.36-224.28h6.894v6.894h-6.894Z" transform="translate(-1131.704 427.994)" fill="#2b468a"/>
        </g>
      </g>
      <g id="Group_7268" data-name="Group 7268" transform="translate(277.032 -1710.04)" clip-path="url(#clip-path-65)">
        <g id="Group_7267" data-name="Group 7267" transform="translate(-182.157 -204.288)" clip-path="url(#clip-path-2)">
          <path id="Path_8041" data-name="Path 8041" d="M1582.81-224.28h6.892v6.894h-6.892Z" transform="translate(-1401.227 427.994)" fill="#2b468a"/>
        </g>
      </g>
      <g id="Group_7270" data-name="Group 7270" transform="translate(243.752 -1752.37)" clip-path="url(#clip-path-67)">
        <g id="Group_7269" data-name="Group 7269" transform="translate(-148.877 -161.958)" clip-path="url(#clip-path-2)">
          <path id="Path_8044" data-name="Path 8044" d="M1292.72-593.26h39.567v4.146H1292.72Z" transform="translate(-1144.417 754.644)" fill="#2b468a"/>
        </g>
      </g>
      <g id="Group_7272" data-name="Group 7272" transform="translate(233.707 -1811.462)" clip-path="url(#clip-path-69)">
        <g id="Group_7271" data-name="Group 7271" transform="translate(-138.832 -102.865)" clip-path="url(#clip-path-2)">
          <path id="Path_8047" data-name="Path 8047" d="M1205.16-1108.353h50.217v60.24H1205.16Z" transform="translate(-1066.902 1210.645)" fill="#f89646"/>
        </g>
      </g>
      <g id="Group_7274" data-name="Group 7274" transform="translate(236.474 -1811.462)" clip-path="url(#clip-path-71)">
        <g id="Group_7273" data-name="Group 7273" transform="translate(-141.599 -102.865)" clip-path="url(#clip-path-2)">
          <path id="Path_8050" data-name="Path 8050" d="M1229.28-1108.353H1279.5v60.24H1229.28Z" transform="translate(-1088.255 1210.645)" fill="#ec7c2b"/>
        </g>
      </g>
      <g id="Group_7276" data-name="Group 7276" transform="translate(233.598 -1758.689)" clip-path="url(#clip-path-73)">
        <g id="Group_7275" data-name="Group 7275" transform="translate(-138.723 -155.639)" clip-path="url(#clip-path-2)">
          <path id="Path_8053" data-name="Path 8053" d="M1204.21-648.34h70.264v7.466H1204.21Z" transform="translate(-1066.061 803.405)" fill="#f89646"/>
        </g>
      </g>
      <g id="Group_7278" data-name="Group 7278" transform="translate(299.789 -1886.896)" clip-path="url(#clip-path-75)">
        <g id="Group_7277" data-name="Group 7277" transform="translate(-204.914 -27.432)" clip-path="url(#clip-path-2)">
          <path id="Path_8056" data-name="Path 8056" d="M1781.18-1765.885h55.412v9.409H1781.18Z" transform="translate(-1576.84 1792.743)" fill="#f8fbff"/>
        </g>
      </g>
      <g id="Group_7280" data-name="Group 7280" transform="translate(340.148 -1871.354)" clip-path="url(#clip-path-77)">
        <g id="Group_7279" data-name="Group 7279" transform="translate(-245.273 -42.973)" clip-path="url(#clip-path-2)">
          <path id="Path_8059" data-name="Path 8059" d="M2132.98-1630.412h32.665v6.185H2132.98Z" transform="translate(-1888.28 1672.812)" fill="#f8fbff"/>
        </g>
      </g>
      <g id="Group_7282" data-name="Group 7282" transform="translate(239.58 -1852.857)" clip-path="url(#clip-path-79)">
        <g id="Group_7281" data-name="Group 7281" transform="translate(-144.705 -61.471)" clip-path="url(#clip-path-2)">
          <path id="Path_8062" data-name="Path 8062" d="M1256.35-1469.178h43.719v7.952H1256.35Z" transform="translate(-1112.219 1530.075)" fill="#f8fbff"/>
        </g>
      </g>
      <g id="Group_7284" data-name="Group 7284" transform="translate(381.7 -1843.235)" clip-path="url(#clip-path-81)">
        <g id="Group_7283" data-name="Group 7283" transform="translate(-286.824 -71.092)" clip-path="url(#clip-path-2)">
          <path id="Path_8065" data-name="Path 8065" d="M2495.17-1385.307h4.883v2.216h-4.883Z" transform="translate(-2208.919 1455.826)" fill="#fff"/>
        </g>
      </g>
      <g id="Group_7286" data-name="Group 7286" transform="translate(295.266 -1807.613)" clip-path="url(#clip-path-83)">
        <g id="Group_7285" data-name="Group 7285" transform="translate(-200.391 -106.715)" clip-path="url(#clip-path-2)">
          <path id="Path_8068" data-name="Path 8068" d="M1741.75-1074.8h49.042v28.763H1741.75Z" transform="translate(-1541.933 1180.939)" fill="#416199"/>
        </g>
      </g>
      <g id="Group_7288" data-name="Group 7288" transform="translate(296.586 -1807.615)" clip-path="url(#clip-path-85)">
        <g id="Group_7287" data-name="Group 7287" transform="translate(-201.711 -106.712)" clip-path="url(#clip-path-2)">
          <path id="Path_8071" data-name="Path 8071" d="M1753.26-1074.818h49.04v28.8h-49.04Z" transform="translate(-1552.123 1180.957)" fill="#223861"/>
        </g>
      </g>
      <g id="Group_7290" data-name="Group 7290" transform="translate(311.159 -1791.468)" clip-path="url(#clip-path-87)">
        <g id="Group_7289" data-name="Group 7289" transform="translate(-216.284 -122.859)" clip-path="url(#clip-path-2)">
          <path id="Path_8074" data-name="Path 8074" d="M1880.29-934.07h15.824v19.527H1880.29Z" transform="translate(-1664.579 1056.356)" fill="#112444"/>
        </g>
      </g>
      <g id="Group_7292" data-name="Group 7292" transform="translate(305.804 -1773.961)" clip-path="url(#clip-path-89)">
        <g id="Group_7291" data-name="Group 7291" transform="translate(-210.929 -140.367)" clip-path="url(#clip-path-2)">
          <path id="Path_8077" data-name="Path 8077" d="M1833.61-781.46H1853.7v2.019H1833.61Z" transform="translate(-1623.255 921.253)" fill="#112444"/>
        </g>
      </g>
      <g id="Group_7294" data-name="Group 7294" transform="translate(208.436 -1820.475)" clip-path="url(#clip-path-91)">
        <g id="Group_7293" data-name="Group 7293" transform="translate(-113.561 -93.852)" clip-path="url(#clip-path-2)">
          <path id="Path_8080" data-name="Path 8080" d="M984.877-1186.916h16.365v46.689H984.877Z" transform="translate(-871.89 1280.195)" fill="#223861"/>
        </g>
      </g>
      <g id="Group_7296" data-name="Group 7296" transform="translate(222.82 -1821.573)" clip-path="url(#clip-path-93)">
        <g id="Group_7295" data-name="Group 7295" transform="translate(-127.945 -92.755)" clip-path="url(#clip-path-2)">
          <path id="Path_8083" data-name="Path 8083" d="M1110.259-1196.482h3.931v4.419h-3.931Z" transform="translate(-982.888 1288.663)" fill="#223861"/>
        </g>
      </g>
      <g id="Group_7298" data-name="Group 7298" transform="translate(208.05 -1810.064)" clip-path="url(#clip-path-95)">
        <g id="Group_7297" data-name="Group 7297" transform="translate(-113.174 -104.264)" clip-path="url(#clip-path-2)">
          <path id="Path_8086" data-name="Path 8086" d="M981.511-1096.163h3.217v3.217h-3.217Z" transform="translate(-868.91 1199.853)" fill="#223861"/>
        </g>
      </g>
      <g id="Group_7300" data-name="Group 7300" transform="translate(213.188 -1775.321)" clip-path="url(#clip-path-97)">
        <g id="Group_7299" data-name="Group 7299" transform="translate(-118.313 -139.006)" clip-path="url(#clip-path-2)">
          <path id="Path_8089" data-name="Path 8089" d="M1026.3-793.32h9.011v3.385H1026.3Z" transform="translate(-908.561 931.753)" fill="#223861"/>
        </g>
      </g>
      <g id="Group_7302" data-name="Group 7302" transform="translate(216.16 -1787.438)" clip-path="url(#clip-path-99)">
        <g id="Group_7301" data-name="Group 7301" transform="translate(-121.285 -126.889)" clip-path="url(#clip-path-2)">
          <path id="Path_8092" data-name="Path 8092" d="M1052.21-898.94h2.8v13.652h-2.8Z" transform="translate(-931.498 1025.256)" fill="#223861"/>
        </g>
      </g>
      <g id="Group_7304" data-name="Group 7304" transform="translate(221.327 -1821.047)" clip-path="url(#clip-path-101)">
        <g id="Group_7303" data-name="Group 7303" transform="translate(-126.452 -93.28)" clip-path="url(#clip-path-2)">
          <path id="Path_8095" data-name="Path 8095" d="M1097.246-1191.9h16.42v15.858h-16.42Z" transform="translate(-971.368 1284.607)" fill="#f89646"/>
        </g>
      </g>
      <g id="Group_7306" data-name="Group 7306" transform="translate(223.414 -1818.361)" clip-path="url(#clip-path-103)">
        <g id="Group_7305" data-name="Group 7305" transform="translate(-128.538 -95.967)" clip-path="url(#clip-path-2)">
          <path id="Path_8098" data-name="Path 8098" d="M1115.433-1168.482h14.334v13.171h-14.334Z" transform="translate(-987.468 1263.875)" fill="#f8ddbe"/>
        </g>
      </g>
      <g id="Group_7308" data-name="Group 7308" transform="translate(226.076 -1816.661)" clip-path="url(#clip-path-105)">
        <g id="Group_7307" data-name="Group 7307" transform="translate(-131.201 -97.666)" clip-path="url(#clip-path-2)">
          <path id="Path_8101" data-name="Path 8101" d="M1138.64-1153.668h6.886v6.479h-6.886Z" transform="translate(-1008.013 1250.761)" fill="#ffeee0"/>
        </g>
      </g>
      <g id="Group_7310" data-name="Group 7310" transform="translate(325.605 -1738.996)" clip-path="url(#clip-path-107)">
        <g id="Group_7309" data-name="Group 7309" transform="translate(-230.73 -175.332)" clip-path="url(#clip-path-2)">
          <path id="Path_8104" data-name="Path 8104" d="M2006.209-476.68h27.043v19.473h-27.043Z" transform="translate(-1776.053 651.438)" fill="#95b8eb"/>
        </g>
      </g>
      <g id="Group_7312" data-name="Group 7312" transform="translate(323.927 -1732.788)" clip-path="url(#clip-path-109)">
        <g id="Group_7311" data-name="Group 7311" transform="translate(-229.052 -181.539)" clip-path="url(#clip-path-2)">
          <path id="Path_8107" data-name="Path 8107" d="M1991.58-422.57h25.626v11.126H1991.58Z" transform="translate(-1763.102 603.536)" fill="#95b8eb"/>
        </g>
      </g>
      <g id="Group_7314" data-name="Group 7314" transform="translate(321.157 -1724.21)" clip-path="url(#clip-path-111)">
        <g id="Group_7313" data-name="Group 7313" transform="translate(-226.282 -190.117)" clip-path="url(#clip-path-2)">
          <path id="Path_8110" data-name="Path 8110" d="M1967.44-347.8h5.166v5.577h-5.166Z" transform="translate(-1741.731 537.344)" fill="#95b8eb"/>
        </g>
      </g>
      <g id="Group_7316" data-name="Group 7316" transform="translate(292.059 -1748.358)" clip-path="url(#clip-path-113)">
        <g id="Group_7315" data-name="Group 7315" transform="translate(-197.183 -165.969)" clip-path="url(#clip-path-2)">
          <path id="Path_8113" data-name="Path 8113" d="M1713.793-558.29h27.355v21.717h-27.355Z" transform="translate(-1517.183 723.686)" fill="#95b8eb"/>
        </g>
      </g>
      <g id="Group_7318" data-name="Group 7318" transform="translate(295.379 -1744.302)" clip-path="url(#clip-path-115)">
        <g id="Group_7317" data-name="Group 7317" transform="translate(-200.504 -170.026)" clip-path="url(#clip-path-2)">
          <path id="Path_8116" data-name="Path 8116" d="M1742.74-522.93h21.666v19.738H1742.74Z" transform="translate(-1542.809 692.382)" fill="#95b8eb"/>
        </g>
      </g>
      <g id="Group_7320" data-name="Group 7320" transform="translate(315.088 -1727.525)" clip-path="url(#clip-path-117)">
        <g id="Group_7319" data-name="Group 7319" transform="translate(-220.213 -186.803)" clip-path="url(#clip-path-2)">
          <path id="Path_8119" data-name="Path 8119" d="M1914.537-376.69h3.543v8.891h-3.543Z" transform="translate(-1694.898 562.919)" fill="#95b8eb"/>
        </g>
      </g>
      <g id="Group_7322" data-name="Group 7322" transform="translate(317.478 -1763.654)" clip-path="url(#clip-path-119)">
        <g id="Group_7321" data-name="Group 7321" transform="translate(-222.603 -150.674)" clip-path="url(#clip-path-2)">
          <path id="Path_8122" data-name="Path 8122" d="M1935.369-691.62h40.338v31.906h-40.338Z" transform="translate(-1713.34 841.72)" fill="#b1cef6"/>
        </g>
      </g>
      <g id="Group_7324" data-name="Group 7324" transform="translate(321.019 -1757.588)" clip-path="url(#clip-path-121)">
        <g id="Group_7323" data-name="Group 7323" transform="translate(-226.143 -156.74)" clip-path="url(#clip-path-2)">
          <path id="Path_8125" data-name="Path 8125" d="M1966.23-638.74h31.832v28.945H1966.23Z" transform="translate(-1740.66 794.906)" fill="#b1cef6"/>
        </g>
      </g>
      <g id="Group_7326" data-name="Group 7326" transform="translate(318.649 -1732.5)" clip-path="url(#clip-path-123)">
        <g id="Group_7325" data-name="Group 7325" transform="translate(-223.774 -181.827)" clip-path="url(#clip-path-2)">
          <path id="Path_8128" data-name="Path 8128" d="M1945.578-420.06h4.729v13.866h-4.729Z" transform="translate(-1722.377 601.314)" fill="#b1cef6"/>
        </g>
      </g>
      <g id="Group_7328" data-name="Group 7328" transform="translate(308.016 -1719.301)" clip-path="url(#clip-path-125)">
        <g id="Group_7327" data-name="Group 7327" transform="translate(-213.141 -195.026)" clip-path="url(#clip-path-2)">
          <path id="Path_8131" data-name="Path 8131" d="M1852.89-305.01h32.423v15.156H1852.89Z" transform="translate(-1640.323 499.463)" fill="#b1cef6"/>
        </g>
      </g>
      <g id="Group_7330" data-name="Group 7330" transform="translate(309.923 -1719.301)" clip-path="url(#clip-path-127)">
        <g id="Group_7329" data-name="Group 7329" transform="translate(-215.047 -195.026)" clip-path="url(#clip-path-2)">
          <path id="Path_8134" data-name="Path 8134" d="M1869.51-305.01h14.523v15.058H1869.51Z" transform="translate(-1655.036 499.463)" fill="#bcd5fa"/>
        </g>
      </g>
      <g id="Group_7332" data-name="Group 7332" transform="translate(307.494 -1719.781)" clip-path="url(#clip-path-129)">
        <g id="Group_7331" data-name="Group 7331" transform="translate(-212.619 -194.547)" clip-path="url(#clip-path-2)">
          <path id="Path_8137" data-name="Path 8137" d="M1848.34-309.19h33.307v1.627H1848.34Z" transform="translate(-1636.295 503.163)" fill="#95b8eb"/>
        </g>
      </g>
      <g id="Group_7334" data-name="Group 7334" transform="translate(316.972 -1705.39)" clip-path="url(#clip-path-131)">
        <g id="Group_7333" data-name="Group 7333" transform="translate(-222.097 -208.937)" clip-path="url(#clip-path-2)">
          <path id="Path_8140" data-name="Path 8140" d="M1930.96-183.75h15.795v2.4H1930.96Z" transform="translate(-1709.436 392.114)" fill="#95b8eb"/>
        </g>
      </g>
      <g id="Group_7336" data-name="Group 7336" transform="translate(204.575 -1770.867)" clip-path="url(#clip-path-133)">
        <g id="Group_7335" data-name="Group 7335" transform="translate(-109.699 -143.461)" clip-path="url(#clip-path-2)">
          <path id="Path_8143" data-name="Path 8143" d="M951.219-754.49h4.916v68.646h-4.916Z" transform="translate(-842.094 897.377)" fill="#2b468a"/>
        </g>
      </g>
      <g id="Group_7338" data-name="Group 7338" transform="translate(343.695 -1770.867)" clip-path="url(#clip-path-135)">
        <g id="Group_7337" data-name="Group 7337" transform="translate(-248.819 -143.461)" clip-path="url(#clip-path-2)">
          <path id="Path_8146" data-name="Path 8146" d="M2163.89-754.49h4.916v68.646h-4.916Z" transform="translate(-1915.644 897.377)" fill="#2b468a"/>
        </g>
      </g>
      <g id="Group_7340" data-name="Group 7340" transform="translate(198.266 -1773.109)" clip-path="url(#clip-path-137)">
        <g id="Group_7339" data-name="Group 7339" transform="translate(-103.39 -141.218)" clip-path="url(#clip-path-2)">
          <path id="Path_8149" data-name="Path 8149" d="M896.225-774.04h157.459v5.126H896.225Z" transform="translate(-793.408 914.685)" fill="#2b468a"/>
        </g>
      </g>
      <g id="Group_7342" data-name="Group 7342" transform="translate(107.17 -1704.273)" clip-path="url(#clip-path-139)">
        <g id="Group_7341" data-name="Group 7341" transform="translate(-12.295 -210.055)" clip-path="url(#clip-path-140)">
          <path id="Path_8152" data-name="Path 8152" d="M102.17-174.01H447.554v2.195H102.17Z" transform="translate(-90.449 383.491)" fill="#9abee1"/>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Amenities"
}
</script>

<style scoped>

</style>
