<template>
  <!--begin: Pagination-->
  <div class="d-flex justify-content-between align-items-center flex-wrap">
    <div class="d-flex flex-wrap py-2 mr-3">
      <b-pagination
          v-if="totalRows > pagination.per_page"
          :total-rows="totalRows"
          @change="changePagination"
          v-model="pagination.page"
          :per-page="pagination.per_page"
          class="my-2"
          :aria-controls="name">
        <template #first-text>
              <span class="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
                <i class="ki ki-bold-double-arrow-back icon-xs"></i>
              </span>
        </template>
        <template #prev-text>
              <span class="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </span>
        </template>
        <template #page="{ page, active }">
          <span class="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">{{ page }}</span>
        </template>
        <template #next-text>
              <span class="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </span>
        </template>
        <template #last-text>
              <span class="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
                <i class="ki ki-bold-double-arrow-next icon-xs"></i>
              </span>
        </template>
        <template #ellipsis-text>
              <span class="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
                <i>...</i>
              </span>
        </template>
      </b-pagination>
    </div>
    <div class="d-flex align-items-center py-3">
<!--              <div class="d-flex align-items-center">-->
<!--                <div class="mr-2 text-muted">Loading...</div>-->
<!--                <div class="spinner spinner-primary mr-10"></div>-->
<!--              </div>-->
      <b-form-select
          class="numPerPage bg-light-primary border-0 mr-4"
          style="width: 55px;"
          size="sm"
          @change.native="changePagination"
          v-model="pagination.per_page" :options="pageOptions"></b-form-select>
      <span>{{ $t("PAGINATION.ROWS_SHOWN") }} {{ pagination.per_page < totalRows ? pagination.count : totalRows }} {{ $t("PAGINATION.FROM") }} {{ totalRows }}</span>
    </div>
  </div>
  <!--end: Pagination-->
</template>

<script>
export default {
  name: "Pagination",
  data() {
    return {
      pageOptions: [10, 20, 50, 100]
    }
  },
  props: [
      'name',
      'pagination'
  ],
  computed: {
    totalRows() {
      return this.pagination.total;
    }
  },
  methods: {
    changePagination() {
      setTimeout(() => {
        this.$emit('changePageLimit', { page: this.pagination.page, limit: this.pagination.per_page })
      }, 200)
    }
  }
}
</script>

<style lang="scss">
  .page-item {
    padding: 0!important;
    margin: 0!important;
    &.disabled {
      .page-link i {
        color: #A6ACC1!important;
      }
    }
    &.active {
      span {
        color: #FFFFFF!important;
        background-color: #5A6589!important;
      }
    }
    .page-link {
      margin: 0!important;
      padding: 0!important;
      border: none!important;
      background: none!important;
    }
  }
</style>
