import { render, staticRenderFns } from "./AmenitiesImage.vue?vue&type=template&id=051d0786&scoped=true&"
import script from "./AmenitiesImage.vue?vue&type=script&lang=js&"
export * from "./AmenitiesImage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "051d0786",
  null
  
)

export default component.exports